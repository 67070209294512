import React from 'react';

import { mystyleActionButton } from './RezeptBuchApp';
import { mystyleForm } from './RezeptBuchApp';
import { mystyleTextArea } from './RezeptBuchApp';
import { mystyleFormInput } from './RezeptBuchApp';

export class NeuesRezeptErfassen extends React.Component {
    constructor(probs) {
        super(probs);
        this.state = {
            rezept: {...this.props.rezept},
            submitButtonStatus: this.props.rezept.name === '' ? true : false,
        };

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeKategorie = this.handleChangeKategorie.bind(this);
        this.handleChangeStichworte = this.handleChangeStichworte.bind(this);
        this.handleChangeBewertung = this.handleChangeBewertung.bind(this);
        this.handleChangeOrt = this.handleChangeOrt.bind(this);
        this.handleChangeTyp = this.handleChangeTyp.bind(this);
        const kategorien = this.props.kategorien;

        
       
    }



    handleChangeName(event) {
        this.state.rezept.name = event.target.value;
        this.setState({ submitButtonStatus: false });
    }
    handleChangeKategorie(event) {
        this.state.rezept.kategorie = event.target.value;
        this.setState({ rezept: {...this.state.rezept} })
    
    }
    handleChangeStichworte(event) {
        this.state.rezept.stichworte = event.target.value;
        this.setState({ rezept: {...this.state.rezept} })
    }
    handleChangeBewertung(event) {
        this.state.rezept.bewertung = event.target.value;
        this.setState({ rezept: {...this.state.rezept} })
    }
    handleChangeOrt(event) {
        this.state.rezept.ort = event.target.value;
        this.setState({ rezept: {...this.state.rezept} })
    }
    handleChangeTyp(event) {
        this.state.rezept.typ = event.target.value;
        this.setState({ rezept: {...this.state.rezept} })
    }
    

    render() {
        const kategorieSelection = this.props.kategorien.map(kategorieEintrag => {
            if (this.state.rezept.kategorie === kategorieEintrag) {return (
                <option selected kategorie={kategorieEintrag}>{kategorieEintrag}</option>)
            } else {
                return (<option  kategorie={kategorieEintrag}>{kategorieEintrag}</option>)
            }
        });

        const bewertungSelection = this.props.bewertungen.map(bewertungEintrag => {
            if (this.state.rezept.bewertung === bewertungEintrag) { 
                return(<option selected bewertung = {bewertungEintrag}> {bewertungEintrag}</option>)
            } else {
                return(<option bewertung = {bewertungEintrag}> {bewertungEintrag} </option>)
            }
        });

        const typSelection = this.props.typen.map(typEintrag => {
            if (this.state.rezept.typ === typEintrag) { 
                return(<option selected typ = {typEintrag}> {typEintrag}</option>)
            } else {
                return(<option typ = {typEintrag}> {typEintrag} </option>)
            }
        });

        
        return (
            <div>
            <form style = {mystyleForm} onSubmit={() => this.props.handleSubmitRezept(this.state.rezept)}>
                <label>
                    {this.props.rezeptBuchFelder.name}:
                    <br></br>
          <textarea style = {mystyleTextArea} name={this.state.rezept.name} value = {this.state.rezept.name} onChange={this.handleChangeName} />
                </label>
                <br></br>
                <label>
                    {this.props.rezeptBuchFelder.kategorie}: 
                    <br></br>
          <select kategorie={this.state.rezept.kategorie} onChange={this.handleChangeKategorie}>
                        { kategorieSelection }
                    </select>
                </label>
                <br></br>
                <label>
                    {this.props.rezeptBuchFelder.stichworte}:
                    <br></br>
          <textarea style = {mystyleTextArea} name={this.state.rezept.stichworte} value = {this.state.rezept.stichworte} onChange={this.handleChangeStichworte} />
                </label>
                <br></br>
                <label>
                    {this.props.rezeptBuchFelder.bewertung}:
                    <br></br>
          <select bewertung={this.state.rezept.bewertung} onChange={this.handleChangeBewertung}>
                        { bewertungSelection }
                    </select>
                </label>
                <br></br>
                <label>
                    {this.props.rezeptBuchFelder.ort}:
                    <br></br>
          <textarea style = {mystyleTextArea} name={this.state.rezept.ort} value = {this.state.rezept.ort} onChange={this.handleChangeOrt} />
                </label>
                <br></br>
                <label>
                    {this.props.rezeptBuchFelder.typ}:
                    <br></br>
          <select typ={this.state.rezept.typ} onChange={this.handleChangeTyp}>
                        { typSelection }
                    </select>
                </label>
            </form> 
            <button style={mystyleActionButton} onClick={() => this.props.handleSubmitRezept(this.state.rezept)} disabled={this.state.submitButtonStatus} >Rezept erfassen</button>
            </div>
        )
    }
}