import React from "react";

import { searchInputs } from './SearchBarStyles';
import { searchDefault } from "./SearchBarStyles";
import { searchIconStyle } from "./SearchBarStyles";
import { searchIconFieldStyle } from "./SearchBarStyles";
import { flexContainer } from "./SearchBarStyles";
import { flexBoxLeft } from "./SearchBarStyles";
import { flexBoxRight } from "./SearchBarStyles";
import { dataResult } from "./SearchBarStyles";

export class SearchBar extends React.Component {
    constructor(probs) {
        super(probs);
        
        
    }

    

render () {
   const suchButton = this.props.suchButton;
   const handleChangeSearch = this.props.handleChangeSearch;
   const handleSearch = this.props.handleSearch;
   
    
    return (
        <div style={flexContainer}>
            <div style={flexBoxLeft}>
                <input type="text" size={30} placeholder={this.props.placeholder} value={((this.props.sucheNeu && this.props.suchText !== '') ? null : this.props.suchText)} onChange={(e) => handleChangeSearch(e.target.value)}/>
            </div>
            <div style={flexBoxRight}>
                <button style={searchIconStyle} disabled={this.props.sucheNeu} onClick={() => handleSearch()} > {suchButton} </button>
            </div>
        </div>

      
    )
}
}
