import React from 'react';

import { RezeptBuchAusgabe } from './RezeptBuchAusgabe';
import { RezeptDetailsAusgabe } from './RezeptDetailsAusgabe';
import { NeuesRezeptErfassen } from './NeuesRezeptErfassen';
import { Impressum } from './../Impressum-DS-AGB/Impressum';
import { Datenschutz } from './../Impressum-DS-AGB/Datenschutz';
import { SearchBar } from '../Components/SearchBar';
import {API_ROOT} from './API';



export const mystyleGlobalFrame = { background: 'orange'}
export const mystyleGlobalTable = { maxHeight:'400px', overflow:'auto', background: 'green' }
export const mystyleHeader = { background: 'lightgreen', textAlign:'center', fontFamily:'Bradley Hand',
    padding:5,  borderRadius: 25, fontSize: "120%"}
export const mystyleTable = {width: '100%', marginTop: 10, fontFamily:'courier'}
export const mystyleTableHeader = {backgroundColor:'yellow'}
export const mystyleTableBody = {backgroundColor:'#d9d9d9'}
export const mystyleTableLine = {textAlign: 'right'}
export const mystyleForm = {backgroundColor:'grey', padding:5 }
export const mystyleTextArea = {width: '95%'}
export const mystyleFormInput = {border: 'red'}
        
export const mystyleActionButton = { background: 'lightyellow', textAlign:'center', fontFamily:'Bradley Hand',
    borderRadius: 25, margin:5 }
const mystyleInfoButton = { background: 'lightblue', textAlign:'center', fontFamily:'Garamond',
    padding:5, margin: 5, borderRadius: 25 }

const BACKEND_URL = API_ROOT;

const rezeptBuchFelder = {
    "id": "Id",
    "name": "Name",
    "kategorie": "Kategorie",
    "stichworte": "Stichworte",
    "ort": "Ort",
    "bewertung": "Bewertung",
    "typ": "Typ"
}

const leeresRezept = {
    "id": 0,
    "name": '',
    "kategorie": 'Hauptgericht',
    "stichworte": '',
    "ort": '',
    "bewertung": 3,
    "typ": "Vegan"
}

const rezeptKategorien = ["Hauptgericht", "Nachspeise", "Vorspeise", "Suppe", "Kuchen", "Süßspeise"];
const rezeptBewertungen = [1, 2, 3, 4, 5, 6];
const rezeptTypen = ["Fleisch", "Fisch", "Vegetarisch", "Vegan"];


const login = async (username, password) => {
    const body= { "username": username, "password": password };
        
    return fetch(BACKEND_URL + '/login/password', {
        method: 'post',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',  
        },
        credentials: 'include', 
    })
    .then((response) => response.json())
    .then((done) => { return done.logged_in})
    .catch((err) => {console.log(err); return false})
}


const logout = async () => {
        
    return fetch(BACKEND_URL + '/logout', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',  
        },
        credentials: 'include', 
    })
    .then((response) => response.json())
    .then((done) => {return done.logged_out})
    .catch((err) => {console.log(err); return false})
}


export class RezeptBuch extends React.Component {
    constructor(props) {
        super(props);
        this.handleRezeptDetails = this.handleRezeptDetails.bind(this);
        this.handleZurueck = this.handleZurueck.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.handleRezeptLoeschen = this.handleRezeptLoeschen.bind(this);
        this.handleNeuesRezept = this.handleNeuesRezept.bind(this);
        this.handleNeuesRezeptEinfuegen = this.handleNeuesRezeptEinfuegen.bind(this);
        this.handleBestehendesRezeptAendern = this.handleBestehendesRezeptAendern.bind(this);
        this.handleRezeptAnpassen = this.handleRezeptAnpassen.bind(this);
        this.handleImpressum = this.handleImpressum.bind(this);
        this.handleDatenschutz = this.handleDatenschutz.bind(this);

        this.state = {
            rezeptBuch: [],
            rezeptListe: [],
            status: 'anmelden',
            rezeptID: 0,
            rezeptBuchFelder: rezeptBuchFelder,
            rezeptIndex: 0,
            rezeptZumAnpassen: {},
            rezeptSuchText: '',
            suchButton: 'S',
            sucheNeu: true,  
            errorText: ''
        };
    }

    componentDidMount() {
        // login().then((login) => {
        //     console.log(login);
        //     this.get_all_recipes();
        // })
    }

    get_all_recipes = async () => { 
        return fetch(BACKEND_URL + '/recipes', {
            credentials: 'include', 
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.length > 0){
                this.setState({rezeptBuch: data, rezeptListe: data, status: 'rezepteAusgeben', errorText: ''})
            }
            else {
                this.setState({errorText: "not array: ", data})
            }
        })
        .catch((err) => {
            this.setState({rezeptBuch: [], rezeptListe: [], status: 'anmelden'})
            console.log(err);
            return [];
        })
    }

    handleAnmelden = async(e) => {
        e.preventDefault();
        var inputData = {
         username : e.target.elements.username.value,
         password : e.target.elements.password.value
        };
        const logged_in = await login(inputData.username, inputData.password);
        console.log("logged_in: " + logged_in);
        if(logged_in) {
            this.get_all_recipes()
        }else {
            this.setState({rezeptBuch: [], rezeptListe: [], status: 'anmelden', errorText: 'Login failed, try agian'})
        }
    }

    handleAbmelden = async (e) => {
        var logged_out = await logout()
        if(logged_out) {
            this.setState({
                rezeptBuch: [], 
                rezeptListe: [], 
                status: 'anmelden',
                errorText: ''
            })
        }else {
            this.setState({
                errorText:"Something went wrong with logout"
            })
        }
    }

    handleImpressum() { this.setState({ status: 'zeigeImpressum' }); }
    handleDatenschutz() { this.setState({ status: 'zeigeDatenschutz' }); }

    handleZurueck() {
        this.setState({
            status: 'rezepteAusgeben',
            rezeptID: 0,
            rezeptListe: this.state.rezeptBuch,
            rezeptSuchText: '',
            suchButton: 'S',
            sucheNeu: true,
        });
    }

    handleSearch() {
        
        const newRezeptListe = this.state.rezeptBuch.filter((item) => (item.name.toLowerCase().includes(this.state.rezeptSuchText.toLowerCase())));
        if (this.state.suchButton === 'X') {
            this.setState({
                suchButton: 'S',
                status: 'rezepteAusgeben',
                rezeptListe: this.state.rezeptBuch,
                sucheNeu: true,
                rezeptSuchText: '',
            });
        } else {
            this.setState({
                suchButton: 'X',
                status: 'rezepteAusgeben',
                rezeptListe: newRezeptListe,
            });
        }
        
    }

    handleChangeSearch(newText) {
        const searchTextNew = newText;
        this.setState({
            rezeptSuchText: searchTextNew,
            suchButton: 'S',
            status: 'rezepteAusgeben',
            sucheNeu: false,
        });
    }

    handleRezeptLoeschen(rezeptID) {
        const newRezeptBuch = this.state.rezeptBuch.filter((item) => item.id !== rezeptID);

        this.setState({
            status: 'rezepteAusgeben',
            rezeptID: 0,
            rezeptBuch: newRezeptBuch,
            rezeptIndex: 0,
            rezeptListe: newRezeptBuch,
            rezeptSuchText: '',
            suchButton: 'S',
            sucheNeu: true,
            errorText: ''
        });

        fetch(BACKEND_URL + '/recipes/' + rezeptID, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            credentials: 'include'
        })
            .then((response) => response.json())
            .then((json) => console.log(json));

        this.setState({
            status: 'rezepteAusgeben',
            rezeptID: 0,
            errorText: ''
        });

    }

    handleRezeptAnpassen(rezeptID) {

        const newRezeptBuch = this.state.rezeptBuch.filter((item) => item.id === rezeptID);
        console.dir(newRezeptBuch[0])
        this.setState({
            status: 'rezeptAnpassen',
            rezeptID: rezeptID,
            rezeptZumAnpassen: newRezeptBuch[0],
        });
    }

    handleRezeptDetails(rezeptID) {
        this.setState({
            status: 'rezeptDetailsAusgabe',
            rezeptID: rezeptID,
        });
    }

    handleNeuesRezept() {
        const laenge = this.state.rezeptBuch.length;
        const rezeptIDNeu = this.state.rezeptBuch[laenge - 1].id + 1;
        this.setState({
            status: 'neuesRezeptErfassen',
            rezeptID: rezeptIDNeu,
        });
        console.log('RezeptID für neues Rezept in Liste: ' + rezeptIDNeu)
    }

    handleBestehendesRezeptAendern(bestehendesRezept) {
        console.log('bestehendes geändertes Rezept Name :  ' + bestehendesRezept.name)
        console.log('bestehendes geändertes Rezept ID :  ' + bestehendesRezept.id)
        
        this.state.rezeptBuch.map((rezept, index) => (rezept.id === bestehendesRezept.id ?
            this.state.rezeptBuch[index] = bestehendesRezept : this.state.rezeptBuch[index] = rezept
        ))
        bestehendesRezept.user_id = 0;
        fetch(BACKEND_URL + '/recipes/' + bestehendesRezept.id, {
            method: 'PUT',
            body: JSON.stringify(bestehendesRezept),
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            credentials: 'include'
        })
            .then((response) => response.json())
            .then((json) => console.log(json));

        this.setState({
            status: 'rezepteAusgeben',
            rezeptID: 0,
            rezeptListe: this.state.rezeptBuch,
            rezeptSuchText: '',
            suchButton: 'S',
            sucheNeu: true,
        });

    }

    handleNeuesRezeptEinfuegen(neuesRezept) {
        console.log('neues Rezept Name :  ' + neuesRezept)
        this.state.rezeptBuch = this.state.rezeptBuch.concat(neuesRezept);
        const laenge = this.state.rezeptBuch.length;
        this.state.rezeptBuch[laenge - 1].id = this.state.rezeptID;
        this.setState({
            status: 'rezepteAusgeben',
            rezeptID: 0,
            rezeptListe: this.state.rezeptBuch,
            rezeptSuchText: '',
            suchButton: 'S',
            sucheNeu: true,
        });
        neuesRezept.user_id = 0;
        fetch(BACKEND_URL + '/recipes', {
            method: 'Post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(neuesRezept),
            credentials: 'include'
        })
            .then((response) => response.json())
            .then((json) => console.log(json));

    }

   

    render() {
        if (this.state.status === "anmelden"){
            return (
                <React.Fragment>
                    <div style={mystyleGlobalFrame}>
                    <form
                    onSubmit={e => {
                        this.handleAnmelden(e);
                    }}
                    >
                    <div className="form-group">
                        <input
                        type="text"
                        className="form-control"
                        name="username"
                        placeholder="Username"
                        />
                    </div>
                    <div className="form-group">
                        <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="Password"
                        />
                    </div>
                    <button value="submit" className="btn btn-primary" margin="20">
                        Submit
                    </button>
                    </form>
                    <p>{this.state.errorText}</p>
                    
                    </div>
                </React.Fragment>
            )
        }

        if (this.state.status === 'rezepteAusgeben') {
            return (
                <React.Fragment>
                    <div style={mystyleGlobalFrame}>
                    <SearchBar placeholder={'suchen'} suchText={this.state.rezeptSuchText} sucheNeu={this.state.sucheNeu} suchButton={this.state.suchButton} handleChangeSearch={this.handleChangeSearch} handleSearch={this.handleSearch}  />
                    <RezeptBuchAusgabe rezeptBuch={this.state.rezeptListe} rezeptSuchText={this.state.rezeptSuchText}
                        rezeptBuchFelder={this.state.rezeptBuchFelder} handleRezeptDetails={this.handleRezeptDetails} />
                    <br></br>
                    <button type='button' style={mystyleActionButton} onClick={this.handleNeuesRezept} > Neues Rezept </button>
                    <br></br>
                    <button type='button' style={mystyleInfoButton} onClick={this.handleAbmelden} > Logout </button>
                    </div>
                </React.Fragment>

            )
        }
        if (this.state.status === 'rezeptDetailsAusgabe') {
            return (
                <React.Fragment>
                <div style={mystyleGlobalFrame}>
                    <h4 style={mystyleHeader}>Rezept Details</h4>
                    <RezeptDetailsAusgabe rezeptBuch={this.state.rezeptBuch} rezeptBuchFelder={this.state.rezeptBuchFelder}
                        rezeptID={this.state.rezeptID} handleZurueck={this.handleZurueck}
                        handleRezeptAnpassen={this.handleRezeptAnpassen}
                        handleRezeptLoeschen={this.handleRezeptLoeschen} />
                        <br></br>
                    <button type='button' style={mystyleInfoButton} onClick={this.handleAbmelden} > Logout </button>
                    </div> </React.Fragment>
            )
        }
        if (this.state.status === 'neuesRezeptErfassen') {
            return (
                <React.Fragment>
                <div style={mystyleGlobalFrame}>
                <h1 style={mystyleHeader}>Neues Rezept</h1>
                    <NeuesRezeptErfassen rezept={leeresRezept} rezeptBuchFelder={this.state.rezeptBuchFelder}
                        kategorien={rezeptKategorien} bewertungen={rezeptBewertungen} typen={rezeptTypen}
                        handleSubmitRezept={this.handleNeuesRezeptEinfuegen} />
                    <br></br>
                    <button type='button' style={mystyleInfoButton} onClick={this.handleAbmelden} > Logout </button>
                </div>
                </React.Fragment>
            )
        }
        if (this.state.status === 'rezeptAnpassen') {
            return (
                <React.Fragment>
                <div style={mystyleGlobalFrame}>
                <h1 style={mystyleHeader}>Rezept ändern</h1>
                    <NeuesRezeptErfassen rezept={this.state.rezeptZumAnpassen} rezeptBuchFelder={this.state.rezeptBuchFelder}
                        kategorien={rezeptKategorien} bewertungen={rezeptBewertungen}  typen={rezeptTypen}
                        handleSubmitRezept={this.handleBestehendesRezeptAendern} />
                        <br></br>
                    <button type='button' style={mystyleInfoButton} onClick={this.handleAbmelden} > Logout </button>
                </div>
                </React.Fragment>
            )
        }
        if (this.state.status === 'zeigeImpressum') {
            return (<React.Fragment>  <Impressum handleZurueck={this.handleZurueck} />
            </React.Fragment>)
        }
        if (this.state.status === 'zeigeDatenschutz') {
            return (<React.Fragment>  <Datenschutz handleZurueck={this.handleZurueck} />
            </React.Fragment>)
        }
        else {
            return (
                <React.Fragment>
                    <h1>Loading!!</h1>
                </React.Fragment>
            );
        }
    }
}