import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react';
import { Button, Container, Navbar } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Header from './Components/Header';
import Main from './Components/Main';
import Footer from './Components/Footer';
import { Registrieren } from './Components/Registrieren';
import { Impressum } from './Impressum-DS-AGB/Impressum';
import { Datenschutz } from './Impressum-DS-AGB/Datenschutz';
import { Layout } from './Components/Layout';
import { NavigationBar } from './Components/NavigationBar';
import { RezeptBuch } from './app/RezeptBuchApp';



function App() {
  return (
    <React.Fragment>
      <Router>
        <NavigationBar />
        <Layout>

          <Routes>
            <Route exact path="/" element={<RezeptBuch />} />
            <Route path="/registrieren" element={<Registrieren />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
          </Routes>

        </Layout>
        
      </Router>
      
    </React.Fragment>
  );
}

export default App;
