import React from "react"; 

export const searchInputs = {
    outerWidth: 5,
    width: '100%',
    padding: 5,
    flex: 1,
}

export const searchDefault = {
    backgroundColor: 'green',
    flex: 1,
    border: 0,
    borderRadius: 2,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    fontSize: 18,
    padding: 10,
    height: 25,
    
}

export const searchIconFieldStyle = {
    
    width: '50%',
    float: 'right',
    
}

export const searchIconStyle = {
    
    borderRadius: 25,
    backgroundColor: 'lightyellow',
    
}


export const dataResult = {
    height: 60,
}

export const flexContainer = {
    boxSizing: 'boder-box',
    display: 'flex',
}

export const flexBoxLeft = {
    padding: 10,
    flex: '70%',
}
export const flexBoxRight = {
    padding: 10,
    flex: '30%',
}