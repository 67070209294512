import React from 'react';

export class RezeptSelektion extends React.Component{
    render () {
        console.log('in Selection rezeptEintrag.id =' + this.props.rezeptEintrag.id)
        console.log('rezeptID =' + this.props.rezeptID)
        if (this.props.rezeptEintrag.id === this.props.rezeptID) {
            
        console.log('rezeptEintrag.id =' + this.props.rezeptEintrag.id)
        return (
            <React.Fragment>
            <tr>
                <td><b>{this.props.rezeptBuchFelder.name}</b></td>
                <td>{this.props.rezeptEintrag.name}</td>
            </tr>
            <tr>
                <td><b>{this.props.rezeptBuchFelder.kategorie}</b></td>
                <td> {this.props.rezeptEintrag.kategorie}</td>
            </tr>
            <tr>
                <td><b>{this.props.rezeptBuchFelder.stichworte}</b></td>
                <td> {this.props.rezeptEintrag.stichworte} </td>
            </tr>
            <tr>
                <td><b>{this.props.rezeptBuchFelder.bewertung}</b> </td>
                <td> {this.props.rezeptEintrag.bewertung} </td>
            </tr>
            <tr>
                <td><b>{this.props.rezeptBuchFelder.ort}</b></td>
                <td> {this.props.rezeptEintrag.ort} </td>
            </tr>
            <tr>
                <td><b>{this.props.rezeptBuchFelder.typ}</b></td>
                <td> {this.props.rezeptEintrag.typ} </td>
            </tr>
            </React.Fragment>
        )}
        return ( <React.Fragment></React.Fragment>);
    }
}