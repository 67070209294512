import React from 'react';
import { RezeptSelektion } from './RezeptSelektion';

import { mystyleGlobalTable } from './RezeptBuchApp';
import { mystyleActionButton } from './RezeptBuchApp';
import { mystyleTable } from './RezeptBuchApp';
import { mystyleTableBody } from './RezeptBuchApp';
import { mystyleTableLine } from './RezeptBuchApp';

export class RezeptDetailsAusgabe extends React.Component {
    
    render() {
        const handleZurueck = this.props.handleZurueck;
        const handleRezeptLoeschen = this.props.handleRezeptLoeschen;
        const handleRezeptAnpassen = this.props.handleRezeptAnpassen;

        console.log('in RezeptDetails Rahmen rezeptID =' + this.props.rezeptID);
        const rezeptDetail = this.props.rezeptBuch.map((rezeptEintrag, index) => (
            <RezeptSelektion rezeptEintrag={rezeptEintrag} rezeptID={this.props.rezeptID} rezeptBuchFelder={this.props.rezeptBuchFelder} />
        

        ))

        return (
            <React.Fragment>
            <div  style={mystyleGlobalTable}>
            <table style={mystyleTable}>
            <tbody style={mystyleTableBody}>{rezeptDetail}</tbody>
            </table>
            
            <table style={mystyleTable}>
            <tbody >
                <tr style = {mystyleTableLine}>
                <td>
                    <button type='button' style={mystyleActionButton} onClick={() => handleZurueck()} > Zurück </button> 
                    <button type='button' style={mystyleActionButton} onClick={() => handleRezeptAnpassen(this.props.rezeptID)} > Ändern </button>
                    <button type='button' style={mystyleActionButton} onClick={() => handleRezeptLoeschen(this.props.rezeptID)} > Löschen </button> 
                    </td> </tr>
                </tbody>
            </table>
            </div>
            </React.Fragment>
        )
    }
}