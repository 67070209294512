import React from 'react';

import { mystyleGlobalTable } from './RezeptBuchApp';
import { mystyleActionButton } from './RezeptBuchApp';
import { mystyleTable } from './RezeptBuchApp';
import { mystyleTableHeader } from './RezeptBuchApp';
import { mystyleTableBody } from './RezeptBuchApp';

export class RezeptBuchAusgabe extends React.Component {
    render() {
        const handleRezeptDetails = this.props.handleRezeptDetails;
        const rezeptSuchText = this.props.rezeptSuchText;
               
        const rezeptEintraege = this.props.rezeptBuch.map((rezeptEintrag, index) => 
           
                <tr>
                <td>
                    {rezeptEintrag.name}
                </td>
                <td>
                    {rezeptEintrag.kategorie}
                </td>
                <td>
                    <button type='button' style={mystyleActionButton} onClick={() => handleRezeptDetails(rezeptEintrag.id)} >Details</button>
                </td>
            </tr>
            
        );

        return (
            
            <div style={mystyleGlobalTable}>
            <table style={mystyleTable}>
                <thead>
                    <tr style={mystyleTableHeader}>
                        <th>
                            {this.props.rezeptBuchFelder.name}
                        </th>
                        <th>
                            {this.props.rezeptBuchFelder.kategorie}
                        </th>
                        <th>
                            Details 
                    </th>
                    </tr>
                </thead>
                <tbody style={mystyleTableBody}>{rezeptEintraege}</tbody>
            </table> 
            </div>
            
       );
    }
}